#alexlinks a:visited {
    text-decoration: none;
    color: #0000FF;
    cursor: pointer;
}

.MuiListItemText-multiline {
    flex: 1 1 !important;
}

.MuiListItem-root {
    align-items: inherit !important;
}
